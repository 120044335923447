"use client"

import dynamic from "next/dynamic"
import React from "react"
import { type Modal, useModalStore } from "stores/modalStore"

const BundleSelectModal = dynamic(() => import("./bundleSelect").then((m) => m.BundleSelectModal), { loading: Placeholder })
const RemoveBundleModal = dynamic(() => import("./removeBundle").then((m) => m.RemoveBundleModal))
const UpgradeHubModal = dynamic(() => import("./upgradeHub").then((m) => m.UpgradeHubModal))
export function Modals() {
  const modals = useModalStore((s) => s.modals)

  return (
    <>
      {Object.entries(modals).map(([key, value]) => {
        return <React.Fragment key={key}>{value && <ModalsFactory key={key} type={key as Modal} />}</React.Fragment>
      })}
    </>
  )
}

function ModalsFactory({ type }: { type: Modal }) {
  switch (type) {
    case "bundleSelect":
      return <BundleSelectModal />    
    case "removeBundle":
      return <RemoveBundleModal />
    case "upgradeHub":
      return <UpgradeHubModal />
    default:
      return null
  }
}

function Placeholder() {
  return <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/80 backdrop-blur-sm"></div>
}
